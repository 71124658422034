@import './vars';

/* font applied in whole body */
body {
    font-family: 'Poppins', sans-serif !important;
    padding-right: 0 !important;
}

.App {
    text-align: start !important;
    display: flex !important;
    flex-direction: column !important;
    min-height: 100vh !important;
}

/* CSS for modal */
.modal-open {
    overflow-y: scroll !important;
}

.modal::-webkit-scrollbar {
    display: none !important;
}

/* CSS for keeping the input value and placeholder align start */
.form-control {
    padding: 0 !important;
}

textarea.form-control {
    padding: 0.375rem 0.25rem !important;
}

/* CSS for making the input field with bottom border */
.input-group-text {
    background-color: transparent;
    border-radius: 50rem !important;
    border: none !important;
}

input, select, .password-input-group {
    border: none !important;
    border-bottom: 1px solid black !important;
    border-radius: 0% !important;
    outline: 0 !important;
}

.mobile-input-group {
    border: none !important;
    border-right: 2px solid black !important;
    border-bottom: 1px solid black !important;
    border-radius: 0% !important;
    outline: 0 !important;
}

.mobile-input {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}

.product-number-input-text {
    border: 1px solid !important;
    border-radius: 0% !important;
}

.search-input::-webkit-input-placeholder { /* WebKit browsers */
    font-size: 12px !important; /* 1em -> input font-size * 1 -> 40px * 1 = 40px */
}

.search-input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    font-size: 12px !important;
}

.search-input::-moz-placeholder { /* Mozilla Firefox 19+ */
    font-size: 12px !important;
}

.search-input:-ms-input-placeholder { /* Internet Explorer 10+ */
    font-size: 12px !important;
} 

.navbar-white-search-input, .navbar-white-search-input::-webkit-input-placeholder { /* WebKit browsers */
    color: white !important;
}

/* CSS for hiding the input outline while selecting */
.form-control:focus, .form-select:focus {
    border-color: inherit !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

/* CSS for input type radio and checkbox */
.form-check-input:checked {
    background-color: $black !important;
}

/* As border is needed only in radio button */
.form-check-input[type=radio] {
    border-radius: 50% !important;
}

/* this css is also needed for radio button as border for input is also none in previous css */
.form-check-input {
    border: 1px solid $black !important;
}

/* CSS for otp input */
.verfiction-input {
    font-size: 3rem !important;
}

/* css for swtich */
#reminder-switch, #vacation-switch {
    border-radius: 50rem !important;
}

#reminder-switch:checked, #vacation-switch:checked {
    background-color: $green-button-color !important;
}

/* css for dropdown */
.datepicker-dropdown {
    .css-sj0jxu-control, .css-sj0jxu-control:hover {
        border: none !important;
    }
}

.react-datepicker-wrapper {
    width: 100% !important;
}

/* CSS for link underline */
a {
    text-decoration-color: $black !important;
}

/* CSS for dropdown item hover in navbar */
.dropdown-item:hover { 
    background-color: $black !important;
    color: $white !important;
}

.menuDropdown, .menu-lg-dropdown {
    .dropdown-menu {
        padding: 0 !important;
    }

    hr {
        height: 2px !important;
    }
} 

.menuDropdown > .dropdown-toggle::after {
    display: none !important;
}

.menu-lg-dropdown {
    width: 100% !important;

    button {
        width: 100% !important;
        text-align: left !important;
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
        border-radius: 0 !important;
        text-transform: uppercase !important;
    }

    .dropdown-menu {
        background-color: #212529  !important;
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
}

.menuNav {
    width: 20rem !important;
}

.language-dropdown {
    a {
        color: white !important;
    }

    .dropdown-menu {
        background-color: #212529  !important;
    }
}

/* css for slick slider icon */
.slick-prev:before {
    content: $left-arrow !important;
}

.slick-next:before {
    content: $right-arrow !important;
}

/* css for creating space between slick slider item */
.slick-list {
    margin: 0 -1rem !important;
}

/* css for same height in slick slider */
.slick-track{
    display: flex !important;
    margin-left: 0 !important;
}

.slick-slide {
    height: inherit !important;
    margin: 0 1.1rem !important;
}

.slick-slide > div { 
    height: 100% !important;
}

/* css for special section slider */
.specialSlider {
    .slick-list, .slick-track {
        height: 100% !important;
    }
    .slick-prev {
        margin-left: 1rem !important;
        position: absolute !important;
        z-index: 1 !important;
        @include custom-slider-button;
    }
    .slick-next {
        margin-right: 1rem !important;
        @include custom-slider-button;
    }
}

.homepage-slider {
    .slick-track {
        justify-content: center !important;
    }
}

/* css for removing the background color and border of bootstrap tab */
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: transparent !important;
    border: none !important;
}

// css for offcanvas
.offcanvas-title {
    text-transform: capitalize !important;
}

/* overwriting bg class of bootstrap */
.bg-secondary {
    background-color: $secondary-background-color !important;
}

.bg-success {
    background-color: $green-button-color !important;
}

/* overwriting btn-success class of bootstrap */
.btn-success {
    background-color: $green-button-color !important;
}

/* overwriting btn-secondary  class of bootstrap */
.btn-secondary {
  background-color: $secondary-button-color !important;
}

/* css for tab of special section */
.specialSectionTabPanel, .priceHistoryTabPanel {
    display: flex !important;
}

/* css for active tab */
.nav-tabs {
    .nav-item > .active {
        border-bottom: 1px solid $black !important;
    }
}

/* css for profile popoveer */
.profile-popover {
    max-width: 400px !important;
    width: 400px !important;

    .popover-arrow {
        transform: translate(323px, 0px) !important;
    }
}

.share-popover {
    max-width: 276px !important;

    .share-popover-body {
        max-width: 276px !important;
        width: 276px !important;
    }
}

/* css for last tab of price history tab of last item */
.priceHistoryTabPanel > li:last-of-type {
    margin-left: auto !important;
}

/* css for tab of trending sneakers section and also for trending snerakers div */
.trendingSneakerTabPanel, .trendingSneaker-div {
    border-bottom: 1px solid #363636 !important;
}

/* css for active tab of special section */
.trendingSneakerTabPanel {
    .trendingTabIcon {
        width: 35px !important;
    }
    .trendingTabNotActive {
        color: #818181 !important;
    }
    .nav-item > .active {
        border-bottom: 1px solid white !important;
    }
}

/* css for breadcrumb */
.breadcrumb {
    margin-bottom: 0 !important;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: $right-arrow !important;
}

.breadcrumb-item {
    a {
        text-decoration: none !important;
        color: #646464 !important;
    }
    font-size: 14px !important;
    font-weight: 400 !important;
}

/* css for accordion */
.accordion-button {
    font-weight: 500 !important;
    color: $black !important;
    background: transparent !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.accordion-button:focus {
    box-shadow: none !important;
    border-color: rgba(0,0,0,.125) !important;
}

.accordion-inner { 
    border-top: none !important;
}

.accordion-button::after  {
    background-image: $up-arrow !important;
}

.accordion-button:not(.collapsed) {
    border: none !important;
    transition: ease 0.25s !important;
}

/* css for progress bar */
.progress-step.is-complete .progress-marker::before, .progress-step.is-progress .progress-marker::before {
    background-color: $green-button-color !important;
}

.progress-step.is-active .progress-marker::before {
    background-color: $green-button-color !important;
}

.progress-step.is-complete .progress-marker::after, .progress-step.is-progress .progress-marker::after {
  background-color: $green-button-color !important;
}

.progress-step.is-complete .progress-marker::after{
    background-color: $green-button-color !important;
}

.progress-step:hover .progress-marker::before {
    background-color: $green-button-color !important;
}

.first-progress .progress-marker::after {
    display: none !important;
}

.progress-step:last-child > .progress-marker::after {
    content: "";
    display: block;
    position: absolute;
    z-index: -10;
    transition: background-color 0.3s, background-position 0.3s;
}

/* css for set content width to 15px */
.w-15 {
    width: 15px !important;
}

.w-32 {
    width: 32px !important;
}

.w-40 {
  width: 40% !important;
}

.w-48 {
    width: 48px !important;
}

.w-54 {
    width: 54px !important;
}

.w-sm-50 {
    @media only screen and (min-width: 576px) {
        width: 50% !important;
    }
}

/* css for height */
.vh-55 {
    height: 55vh !important;
}

.vh-75 {
    height: 75vh !important;
}

/* css for size guide text in single product details page */
.sizeGuideText {
    color: #707070 !important;
}

// font size
.fs-8 {
    font-size: 8px !important;
}

.fs-10 {
    font-size: 10px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-25 {
    font-size: 25px !important;
}

.fs-28 {
    font-size: 28px !important;
}

.fs-80 {
    font-size: 80px !important;
}

// font weight
.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

// css for text color
.text-success {
    color: $green-text-color !important;
}

.text-danger {
    color: $red-text-color !important;;
}

#user-dropdown::after {
    display: none !important; 
}

// css for product card
.product-card-image {
    width: 172px !important;
    height: 114px !important;
}

// css for avatar input
.avatar-upload {
    position: relative;
    max-width: 205px;

    .avatar-preview {
        width: 128px;
        height: 128px;
    }

    .avatar-input {
        position: absolute;
        right: 5.1rem !important;
        z-index: 1;
        top: 5rem !important;
        input {
            display: none;
            + label {
                display: inline-block;
                width: 32px;
                height: 32px;
                margin-bottom: 0;
                border-radius: 100%;
                background: #FFFFFF;
                border: 1px solid #d2d6de;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                cursor: pointer;           
                font-weight: normal;
                transition: all .2s ease-in-out;
                &:hover {
                    background: #f1f1f1;
                    border-color: #d6d6d6;
                }
            }
        }
    }
}

// css for avatar in navbar
.navbar-avatar {
    width: 32px !important;
    height: 32px !important;
}

.login-button {
    @media only screen and (min-width: 1200px) and (max-width: 1300px) {
        min-width: 105px !important;
    }
}

// css for lucky card
.lucky-card {
    width: 48px !important;
    height: 48px !important;
}

// css for position
.start-85 {
    left: 85% !important;
}

.fixed-top {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    z-index: 1030 !important;
}

// css for carousel
.carousel-item img {
    @media only screen and (max-width: 767px) {
        height: 100%; /* Bootstrap handles width already */
        object-fit: cover; /* or 'contain' if you want stretch instead of crop */
    }
}

.homepage-carousel {
    .carousel-inner {
        height: 0;
        padding-bottom: 25%; /* this sets carousel aspect ratio (4:1 here) */
    }

    .carousel-item {
        position: absolute !important; /* Bootstrap is insistent */
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    @media only screen and (max-width: 767px) {
        height: 300px !important;

        .carousel-inner {
            height: 300px !important;
        }

        .carousel-item img {
            height: 300px !important;
        }
    }
}

.product-carousel {
    @media only screen and (min-width: 992px) {
        .carousel-inner {
            padding-bottom: 25%; /* this sets carousel aspect ratio (4:1 here) */
            height: 100% !important;
            display: flex !important;
            align-items: center !important;
        }

        .carousel-item {
            position: absolute !important; /* Bootstrap is insistent */
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    .carousel-indicators {
        justify-content: space-evenly !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .carousel-indicators [data-bs-target] {
        background-color: black !important;
        flex-grow: 1 !important;
    }
}

// css for react-select
.css-1fdsijx-ValueContainer {
    padding-left: 0 !important;
}

.css-13cymwt-control {
    border: none !important;
    background-color: transparent !important;
    border-bottom: 1px solid black !important;
    border-radius: 0%;
}

.css-13cymwt-control:focus {
    border: none !important;
    background-color: transparent !important;
    border-bottom: 1px solid black !important;
    border-radius: 0% !important;
}

.css-1u9des2-indicatorSeparator {
    display: none !important;
}

.css-qbdosj-Input > input {
    border: none !important;
}

.css-1xc3v61-indicatorContainer {
    color: black !important;
}

// css for border
.rounded-bottom {
    border-bottom-right-radius: 3rem !important;
    border-bottom-left-radius: 3rem !important;
}

// css for range-input
.css-112d2bc-InputLabel {
    display: none !important;
}

.css-j8gyih-TwoThumbInputRange {
    margin: 0 !important;
    width: 100% !important;
}

.css-1pg5j8f-InputRange {
    border: none !important;
}

.css-j8gyih-TwoThumbInputRange > div:nth-child(2) {
    width: 100% !important;
    height: 7px !important;
}

// css for padding
.px-2_5 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
}

.py-2_5 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

.next-item:hover, .prev-item:hover {
    background-color: black !important;
    color: white !important;
}

/* css for sweetalert */
.swal2-confirm {
    display: none !important;
}

.swal2-html-container {
    margin: 0 !important;
    padding: 0 !important;
}

.swal2-popup {
    padding: 0 !important;
}

.swal2-actions {
    display: none !important;
}

.swal2-close:focus {
    box-shadow: none !important;
}

// css for pagination
.pagination > li:first-of-type > a:hover {
    background: black !important;
    color: white !important;
    font-size: 14px !important;
}

.pagination > li:last-child > a:hover {
    background: black !important;
    color: white !important;
    font-size: 14px !important;
}

// css for opacity
.opacity-95 {
    opacity: 0.95 !important;
}

// css for z-index
.z-2 {
    z-index: 2 !important;
}

.img-container {
    padding-bottom: 75% !important; 
    height: 0 !important;
    overflow: hidden !important;
    position: relative !important;
}

.img-container img {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
}

.bag-image {
    width: 100% !important;
    height: 120px !important;
    object-fit: cover !important;
}

.sneaker-card-image {
    width: 210px !important;
    height: 160px !important;
    object-fit: cover !important;
}

.raffle-join .btn-close {
    align-self: self-start !important;
}

.raffle-history-price-button {
    width: 75px !important;
    background-image: url('../../image/raffle/button-background.png');
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
}

.cart-quantity-button {
    width: 110px !important;
    height: 35px !important;
}

// css for border
.border-start-5 {
    border-left: 5px solid !important;
}

.border-start-success {
    border-left-color: $success !important;
}

.border-start-danger {
    border-left-color: red !important;
}

.news-row > .tab-content {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.text-orange {
    color: #F96411 !important;
}

// css for brand image
.brand-image {
    width: 120px !important;
    height: 105px !important;
    object-fit: cover !important;
}

.product-card {
    width: 300px !important;
    
    img {
        width: 300px !important;
        height: 250px !important;
        object-fit: cover !important;
    }
}

.feature-image {
    width: 280px !important;
    height: 380px !important;
    object-fit: cover !important;
}

.new-arrival-image {
    width: 250px !important;
    height: 200px !important;
    object-fit: cover !important;
}


.new-arrival-card {
    width: 250px !important;
}

.new-arrival-card-name {
    display: grid !important;
    width: 250px !important;
}

// css for display
.d-table-cell {
    display: table-cell !important;
}

.d-grid {
    display: grid !important;
}

// css for margin
.mt-5 {
    margin-top: 5rem !important;
}

// css for content page
.content {
    p {
        margin-bottom: 0 !important;
    }
}

/* CSS for skeleton */
.bg-skeleton {
	background-color: #EBEBEB !important;
}

.add-to-bag-button-skeleton {
	height: 29px !important;
}

.new-arrival-image-skeleton {
    width: 250px !important;
    height: 200px !important;
}

.brand-image-skeleton {
    width: 120px !important;
    height: 105px !important;
}

.feature-image-skeleton {
    width: 280px !important;
    height: 380px !important;
}

.fs-10-skeleton {
    height: 15px !important;
}

.fs-14-skeleton {
    height: 21px !important;
}

.fs-18-skeleton {
    height: 27px !important;
}