/* authenication pages border responsive css */
@media only screen and (min-width: 576px) {
    .authDiv {
        border: 1px solid #dee2e6;
    }
}

/* css for single product pages size tab and border left in lg device */
@media only screen and (min-width: 992px) {
    .sizeDiv {
        height: 320px !important;
        overflow: auto !important;
    }

    .border-lg-start {
        border-left: 1px solid #dee2e6 !important;
    }
}

@media only screen and (min-width: 1700px) {
    .sizeDiv {
        height: 90vh !important;
        overflow: auto !important;
    }
}

@media only screen and (max-width: 991px) {
    .progress-tracker--vertical .progress-marker::after {
        top: -100% !important;
    }

    .progress-tracker--vertical .progress-marker::after {
        content: "";
        display: block;
        position: absolute;
        z-index: -10;
        transition: background-color 0.3s, background-position 0.3s;
    }
}

@media only screen and (min-width: 992px) {
    .progress-marker::after {
        content: "" !important;
        display: block !important;
        position: absolute !important;
        z-index: -10 !important;
        top: 10px !important;
        right: 50% !important;
        width: 100% !important;
        height: 4px !important;
        transition: background-color 0.3s, background-position 0.3s !important;
    }
}

/* display responsive css */
@media only screen and (min-width: 992px) {
    .bg-lg-transparent {
        background: transparent !important;
    }
}

/* navbar responsive css */
@media only screen and (max-width: 992px) {
    .navbar-logo {
        width: 130px !important;
        max-width: 100% !important;
    }
}

/* margin responsive css */
@media only screen and (min-width: 992px) {
    .mx-lg-row {
        margin-right: calc(-0.5 * var(--bs-gutter-x)) !important;
        margin-left: calc(-0.5 * var(--bs-gutter-x)) !important;
    }
}

// css for border
@media only screen and (min-width: 992px) {
    .border-lg-0 {
        border: none !important;
    }

    .border-lg {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important
    }

    .rounded-lg {
        border-radius: var(--bs-border-radius) !important;
    }
}