// Poppins Font
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

// base variable
@import './base/vars';

// layout
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

// Progress tracker css
@import "node_modules/progress-tracker/src/styles/progress-tracker.scss";

// base
@import 'base/mixin';
@import 'base/responsive';
@import 'base/common';
